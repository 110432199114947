/* eslint-disable no-console */

import { register } from 'register-service-worker'

if (process.env.NODE_ENV === 'production' && !process.env.IS_ELECTRON) {
  register(`${process.env.BASE_URL}service-worker.js`, {
    ready () {
      console.log(
        'App is being served from cache by a service worker.\n' +
        'For more details, visit https://goo.gl/AFskqB'
      )
    },
    registered () {
      console.log('Service worker has been registered.')
    },
    cached () {
      console.log('Content has been cached for offline use.')
    },
    updatefound () {
       // Solo ejecutamos el contenido de updatefound si hay un SW activo
       if (navigator.serviceWorker.controller) {
        console.log('New content is downloading.')
        document.dispatchEvent(
          new CustomEvent('swUpdate')
        )
      } else {
        console.log('New service worker installing for the first time, no previous SW.')
      }
    },
    updated (registration) {
      console.log('New content is available; please refresh.')
      document.dispatchEvent(
        new CustomEvent('swUpdated', { detail: registration })
      )
    },
    offline () {
      console.log('No internet connection found. App is running in offline mode.')
    },
    error (error) {
      console.error('Error during service worker registration:', error)
    }
  })
}

self.addEventListener('message', (event) => {
  if (event.data && event.data.type === 'SKIP_WAITING') {
    (self as any).skipWaiting()
  }
})

self.addEventListener('install', function(event) {
  console.log('Service worker installing...');
  // Add new files to cache
});

self.addEventListener('activate', function(event:any) {
  console.log('Service worker activating...');
  event.waitUntil(
      caches.keys().then(function(cacheNames) {
          return Promise.all(
              cacheNames.map(function(cacheName) {
                  if (cacheName !== 'your-new-cache-name') {
                      return caches.delete(cacheName);
                  }
              })
          );
      })
  );
});