<template name="home">
  <div class="home-page relative text-white">
    <!-- FEATURED -->
    <section class="mb-6 lg:mb-10" v-if="!$store.state.kioskMode">
      <div class="swiper px-4 lg:px-10" ref="featured" v-show="featured && featured.length > 0">
        <div class="swiper-wrapper">
          <div class="swiper-slide" v-for="(item, index) in featured" :key="index">
            <Banner v-if="item" :image="item.url" />
          </div>
          <div class="swiper-pagination"></div>
        </div>
      </div>
      <!-- Skeleton -->
      <div class="px-4 lg:px-10 animate-pulse" v-show="featured && featured.length == 0">
        <Banner />
      </div>
    </section>

    <!-- NOW PLAYING -->
    <section class="mb-6 lg:mb-10 space-y-2">
      <!-- Section Title -->
      <p class="
          px-4
          lg:px-10
          font-bold
          lg:text-2xl
        " v-if="!$store.state.kioskMode">
        Comprá tu entrada
      </p>

      <!-- cinema selector -->
      <!--Dropdown class="cinemaSelector w-full md:w-1/2 px-4 pb-2" v-if="$store.state.cinemas.length > 0" :options="$store.state.cinemas" @onUpdate="selectCinema($event)">
      </Dropdown-->

      <!-- section content-->
      <div v-if="nowPlaying.length > 0" class="showtimes grid grid-cols-2 md:grid-cols-4 gap-x-4 gap-y-8 px-4 lg:px-10">
        <router-link :to="'/pelicula/' + $store.state.cinemaInfo.id + '/' + item.pref" v-for="(item, index) in nowPlaying"
          :key="index">
          <MovieItem :title="item.nombre" :poster="(item.poster) ? item.poster.path : noPoster" :formato="item.formato"
            :lenguaje="item.lenguaje" />
        </router-link>
      </div>

      <div v-else class="showtimes grid grid-cols-2 md:grid-cols-4 gap-4 px-4 lg:px-10">
        <MovieItem v-for="index in 4" :key="index" />
      </div>
    </section>

    <!-- WHATSAPP -->
    <a :href="'https://wa.me/' + cinemaInfo.Whatsapp" target="blank" v-if="$store.state.cinemaInfo.Whatsapp">
      <div class="whatsAppButton bg-green-700 w-14 h-14 p-4 rounded-full fixed bottom-4 right-4 z-10 shadow-md">
        <img class="w-full h-full object-contain object-center" src="/assets/icons/whatsapp-brands.svg" alt="">
      </div>
    </a>
  </div>
</template>

<script lang="ts">
// Utils
import utilities from "../utilities";

//Vuex
import { mapState } from "vuex";

// Swiper
import { Swiper } from 'swiper';
import { Autoplay } from 'swiper/modules'

// Import Swiper styles
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'

// Custom Components
import Banner from "@/components/Banner.vue"
import MovieItem from "@/components/MovieItem.vue"

import { defineComponent } from "vue";

export default defineComponent({
  name: 'HomeView',
  components: {
    Banner,
    MovieItem
  },
  data() {
    return {
      featured: [] as any[any],
      config: [] as any[any],
      nowPlaying: [] as any[any],
      comingSoon: [] as any[any],
      noPoster: '/assets/img/no_poster.jpg',
      showSomingSoon: false
    };
  },
  mounted() {
    //scrollto top
    window.scrollTo(0, 0);

    // get now playing
    if (this.$store.state.cinemaInfo.id) {
      this.getFeatured()
      this.getNowPlaying()
    }

    //get thu
    const thisThu = utilities.getThu();
  },
  computed: mapState(['cinemaInfo']),
  methods: {
    getFeatured() {
      const companyId = this.$store.state.cinemaInfo.idEmpresa
      const getBanners = utilities.getFromApi('/banners/' + companyId)

      Promise.resolve(getBanners).then((response) => {
        this.featured = response.data.data

        //create featured swiper
        let featuredOpts = {
          centeredSlides: true,
          speed: 0,
          loop: false,
          autoplay: {},
          spaceBetween: 8,
          breakpoints: {
            768: {
              spaceBetween: 16,
            },
          },
          modules: [Autoplay]
        };

        //loop and autoplay
        if (this.featured && this.featured.length > 1) {
          featuredOpts.speed = 500
          featuredOpts.loop = true,
            featuredOpts.autoplay = {
              delay: 5000,
            }
        }

        if (this.featured && this.featured.length > 0) {
          setTimeout(() => {
            new Swiper(this.$refs.featured as any, featuredOpts)
          }, 1)
        }
      })
    },
    getNowPlaying() {
      this.nowPlaying = {}
      const cinemaId = this.$store.state.cinemaInfo.id
      const getNowPlaying = utilities.getFromApi('/nowPlaying/' + cinemaId)

      Promise.resolve(getNowPlaying).then((response) => {
        this.nowPlaying = response.data.data
      })
    },
    async getCinemaInfo(cinemaId:number){
      //get cinema info
      const cinemaInfo = await utilities.getFromApi("/cinemas/" + cinemaId);

      if (cinemaInfo.data.status == "ok") {
        this.$store.commit("setCinemaInfo", cinemaInfo.data.data);
      } else {
        const toast = this.$refs.toast as any
        toast.show("Hubo un problema al cargar la información de este cine. Por favor, recargue la web.")
      }
    },
    selectCinema(cinemaData: any) {
      this.getCinemaInfo(cinemaData)
      this.getNowPlaying()
    },
  }/*,
  watch: {
    cinemaInfo(data) {
      if (data.id) {
        this.getFeatured()
        this.getNowPlaying()
      }
    }
  }*/

})
</script>

<style scoped>
.main {
  top: 36em;
}
</style>
